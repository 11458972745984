import React, { useState } from "react"
import { Collapse, Button } from 'reactstrap';
import { graphql } from "gatsby"
import useTranslations from "../../../utils/useTranslations"
import { parseFaq } from "../../../utils/wordpress"
import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"


const TokenBittorrentSpeedFAQ = ({ pageContext: {text}, data }) => {
const t = useTranslations(text)
const parsedFaqData = parseFaq(data.allWordpressWpFaqSect.edges, data.allWordpressWpCustomFaqs.edges)

  return (
  	<>
  		{/* Start - hero */}
        <div className="text-center top-section-container p-0 py-5 background-noise-dark" style={backgrounds.darkBg}>

          <div className="container text-white">

              <div className="mt-md-3 py-md-3 order-md-1 px-md-0">
                <Logo color="white-speed" tag="h1" productName="Speed" className="mb-4"/>
                <p className="text-size-30 text-spacing-1 font-weight-normal">
                  {t(`Frequently Asked Questions`)}
                </p>
                <p className="text-spacing-1 font-weight-normal text-size-20">
                  {t(`Find in-depth information about the Wallet release here.`)}
                </p>
              </div>

          </div>
          
        </div> 
        {/* End - Hero */}
	    <div className="container my-5">
	      <Faq data={parsedFaqData} />
	    </div>
	</>
  )
}

export default TokenBittorrentSpeedFAQ

export const Faq = (props) => {
	let faq = props.data.versions.map((version, index) => {
		let sectionName = version.version
		let sectionClassName = 'faq-section-' + sectionName.replace(/ +/g, '-').toLowerCase()

		let questions = version.elements.map((element, index2) => {
		let questionId = `toggler-${index}-${index2}`
			return (
				<div key={questionId}>
					<AccordianCard
					index = {index2}
					title = {element.question}
					>
					{element.answer}
					</AccordianCard>
				</div>

			)
		})
		return(
			<div className={`py-3 ${sectionClassName}`} key={`toggler-${index}`}>
				<div className="font-weight-bold text-size-24 mt-3">
					{sectionName}
				</div>
				{questions}
			</div>

		)
	})

	return(
		<div>
			{faq}
		</div>
	)
}


const AccordianCard = (props) => {
 	let { title, index } = props

 	let initState = (index === 0) ? true : false;

	const [collapse, setCollapse] = useState(initState);

	const toggle = () => setCollapse(!collapse);

	const show = (collapse) ? "show" : ""
 	
	const titleId = title.replace(/\?/g, '').replace(/\s+/g, '-');

 	return(

 		<>
	 	<Button 
			id={titleId} 
			className={`${show} w-100 bg-white text-dark text-left border-0 accordian-button position-relative py-3 `}
			onClick={toggle}
			>
			<div className="accordian-button-title" >
				<div dangerouslySetInnerHTML={{__html:title}} className="faq-question"></div>
			</div>
		</Button>
		<Collapse isOpen={collapse} className={`px-4 accordian-card text-left`}>
	    	<div dangerouslySetInnerHTML={{__html:props.children}}></div>
	    </Collapse>
		</>
	)

}

export const pageQuery = graphql`
query querySpeedFaq {
  allWordpressWpFaqSect {
    edges {
      node {
        name
        wordpress_id
      }
    }
  }
  allWordpressWpCustomFaqs(sort: {fields: menu_order, order: ASC}, filter: {faq_cat: {eq: 1804}}) {
    edges {
      node {
        title
        content
        wordpress_id
        id
        menu_order
        faq_cat
        faq_sect
      }
    }
  }
}

`