import React from "react"

function parseFaq(sections, posts) {
  let nodes = []

  sections.forEach((section, index) => {
    let sectionPosts = posts.filter((x) => x.node.faq_sect.includes(section.node.wordpress_id))

    let versions = {
      elements: [],
      version: section.node.name,
      order: index
    }

    sectionPosts.forEach((post) => {

      let question = post.node.title
      let answer = post.node.content
      let listItem = {
        question:question,
        answer:answer
      }
      versions.elements.push(listItem)
    })
    nodes.push(versions)
  })

  //filter out empty sections
  nodes = nodes.filter((node) => node.elements.length > 0 )
  return {versions: nodes}

}

export {parseFaq}



